import {
  Project,
  SupplierConfig
} from '@nexploretechnology/concreting-core-client/concrete/project.management-client/project.management.dto';
import {
  UserCompany,
  UserProfile
} from '@nexploretechnology/concreting-core-client/concrete/user.management-client/user.management.dto';
import { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SUPPLIER_VALUES } from '../constants/suppliers';
import { ISupplierData, SUPPLIERS_DATA } from '../constants/suppliersData';
import useGlobalState from '../hooks/useGlobalState';
import { ProjectRoles } from '../services/authService';
import { getProjectPermissions } from '../services/projectService';
import getProjectBusinessPages from '../services/projectService/getProjectBusinessPages';

export interface ProjectContextValue {
    marketId?: string;
    supplierId?: string;
    companyId?: string;
    company: UserCompany;
    projectId?: string;
    project: Project;
    projectRoles: ProjectRoles;
    supplierConfig: SupplierConfig;
    supplierData?: ISupplierData;
    projectBusinessPages: string[];
    projectPermissions: {
        allowCreateOrderLoadLite?: boolean;
        allowCreateOrderLoadLiteQR?: boolean;
        allowCreateOrderLoadLiteOCR?: boolean;
    };
}

const ProjectContext = createContext<ProjectContextValue>({
    project: {},
    supplierConfig: {},
    projectRoles: {}
} as ProjectContextValue);

function findCompany(projectId?: string, userProfile?: UserProfile) {
    if (!userProfile || !projectId) {
        return undefined;
    }

    return userProfile?.companies.find((company) => {
        return company?.projects?.find((project) => project.projectId === projectId);
    });
}

function ProjectContextProvider({ children }: { children: unknown }) {
    const [projectInfo, setProjectInfo] = useState<ProjectContextValue>({
        project: {},
        supplierConfig: {}
    } as ProjectContextValue);

    const { api, userProfile } = useGlobalState();
    const { projectId } = useParams();

    useEffect(() => {
        const company = findCompany(projectId, userProfile);

        if (company && projectId) {
            Promise.all([
                api.projectManagementClient.getSupplierConfig(company.companyId, projectId),
                api.projectManagementClient.getProject(company.companyId, projectId)
            ]).then(([responseSupplierconfig, responseProject]) => {
                const project = responseProject.getEntity();
                const companyProject = company.projects.find((c) => c.projectId === projectId);
                const supplierConfig = responseSupplierconfig.getEntity();
                const projectRoles = companyProject?.projectRoles || {};

                setProjectInfo({
                    marketId: project?.marketId,
                    supplierId: supplierConfig?.supplierSoftware || supplierConfig?.supplierName?.toLocaleLowerCase(),
                    supplierConfig,
                    project,
                    projectRoles,
                    projectId,
                    companyId: company.companyId,
                    company,
                    supplierData: SUPPLIERS_DATA[supplierConfig?.supplierSoftware as SUPPLIER_VALUES],
                    projectPermissions: getProjectPermissions({ supplierConfig }),
                    projectBusinessPages: getProjectBusinessPages({ marketId: project?.marketId })
                });
            });
        }
    }, [api, projectId, userProfile, setProjectInfo]);

    return <ProjectContext.Provider value={projectInfo}>{children}</ProjectContext.Provider>;
}

export { ProjectContext, ProjectContextProvider };
