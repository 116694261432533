import { useEffect, useMemo } from 'react';
import { MARKETS } from 'src/app-react/constants/markets';
import useGlobalState from 'src/app-react/hooks/useGlobalState';
import { getConfigService } from 'src/app-react/services/configService';
import BugButton from './BugButton';
import IncidentButton from './IncidentButton';
import QuestionButton from './QuestionButton';
import EnhencementButton from './enhencementButton';
function FeedbackButton() {
    const config = getConfigService();
    const { userProfile } = useGlobalState();
    const showUatButtons = config.environment === 'uat';
    const showProdButtons = config.environment.includes('prod-');
    const isAusMarket = useMemo(
        () =>
            userProfile?.companies?.find((company) =>
                company?.projects?.find((project) => project.marketId === MARKETS.AUS)
            ),
        [userProfile]
    );

    useEffect(() => {
        const script: HTMLScriptElement = document.createElement('script');
        script.innerHTML = `            
            window.ATL_JQ_PAGE_PROPS =  {
                'bd3eceea': {
                "triggerFunction": function(showCollectorDialog) {
                    //Requries that jQuery is available! 
                    jQuery("#incident_button").click(function(e) {
                        e.preventDefault();
                        showCollectorDialog();
                    });
                },
					fieldValues: {
						//Enviroment checkbox checked by default
						recordWebInfo: '1', 
						recordWebInfoConsent: ['1'] 
					}
                },
                '01819c8d': {
                    "triggerFunction": function(showCollectorDialog) {
                        //Requries that jQuery is available! 
                        jQuery("#question_button").click(function(e) {
                            e.preventDefault();
                            showCollectorDialog();
                        });
                    },fieldValues: {
						//Enviroment checkbox checked by default
						recordWebInfo: '1', 
						recordWebInfoConsent: ['1'] 
					}
                },
                '6c0480ff': {
                    "triggerFunction": function(showCollectorDialog) {
                        //Requries that jQuery is available! 
                        jQuery("#bug_button").click(function(e) {
                            e.preventDefault();
                            showCollectorDialog();
                        });
                    },fieldValues: {
						//Enviroment checkbox checked by default
						recordWebInfo: '1', 
						recordWebInfoConsent: ['1'] 
					}
                },'a3ad4ca7': {
                    "triggerFunction": function(showCollectorDialog) {
                        //Requries that jQuery is available! 
                        jQuery("#enhencement_button").click(function(e) {
                            e.preventDefault();
                            showCollectorDialog();
                        });
                    },fieldValues: {
						//Enviroment checkbox checked by default
						recordWebInfo: '1', 
						recordWebInfoConsent: ['1'] 
					}
                },
                'fd922935': {
                    "triggerFunction": function(showCollectorDialog) {
                        //Requries that jQuery is available! 
                        jQuery("#incident_button").click(function(e) {
                            e.preventDefault();
                            showCollectorDialog();
                        });
                    },fieldValues: {
						//Enviroment checkbox checked by default
						recordWebInfo: '1', 
						recordWebInfoConsent: ['1'] 
					}
                },
                '6e0a807a': {
                    "triggerFunction": function(showCollectorDialog) {
                        //Requries that jQuery is available! 
                        jQuery("#question_button").click(function(e) {
                            e.preventDefault();
                            showCollectorDialog();
                        });
                    },fieldValues: {
						//Enviroment checkbox checked by default
						recordWebInfo: '1', 
						recordWebInfoConsent: ['1'] 
					}
                },
                '32612dc3': {
                    "triggerFunction": function(showCollectorDialog) {
                        //Requries that jQuery is available! 
                        jQuery("#bug_button").click(function(e) {
                            e.preventDefault();
                            showCollectorDialog();
                        });
                    },fieldValues: {
						//Enviroment checkbox checked by default
						recordWebInfo: '1', 
						recordWebInfoConsent: ['1'] 
					}
                },'194e826e': {
                    "triggerFunction": function(showCollectorDialog) {
                        //Requries that jQuery is available! 
                        jQuery("#enhencement_button").click(function(e) {
                            e.preventDefault();
                            showCollectorDialog();
                        });
                    },fieldValues: {
						//Enviroment checkbox checked by default
						recordWebInfo: '1', 
						recordWebInfoConsent: ['1'] 
					}
                }
            };        
            `;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    if (showProdButtons) {
        return (
            <>
                <IncidentButton />
                <QuestionButton />
            </>
        );
    }
    if (showUatButtons && !isAusMarket) {
        return (
            <>
                <BugButton />
                <EnhencementButton />
            </>
        );
    }
    if (showUatButtons && isAusMarket) {
        return <BugButton />;
    }
    return <></>;
}

export default FeedbackButton;
