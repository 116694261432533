import { Trans } from '@lingui/macro';
import { Form, Radio } from 'antd';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';

export function SelectMarketFormItem({ disabled }: { disabled: boolean }) {
    return (
        <>
            <Form.Item name="marketId" rules={[requiredRule]}>
                <Radio.Group disabled={disabled}>
                    <Radio value="DE" checked data-testid="marketId-DE">
                        <Trans>Germany</Trans>
                    </Radio>
                    <Radio value="US" data-testid="marketId-US">
                        <Trans>USA</Trans>
                    </Radio>
                    <Radio value="AUS" data-testid="marketId-AUS">
                        <Trans>Australia</Trans>
                    </Radio>
                </Radio.Group>
            </Form.Item>

            {disabled && (
                <Trans>
                    The project's market cannot be changed. The project already has a catalogue and/or orders have been
                    created
                </Trans>
            )}
        </>
    );
}
